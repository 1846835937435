<div class="signin-container">
    <div class="signin-box col-md-4">
        <div class="logo-container">
            <!-- <img class="logo" src="assets/images/tbd-logo.png" alt="" /> -->
        </div>
        <h4 class="text-center setP">Forgot Password</h4>
        <!-- <div class="alert alert-danger" role="alert" *ngIf="forgotError">{{ forgotError }}</div> -->
        <form #forgotForm="ngForm" (ngSubmit)="forgotPassword(forgotForm.form)">
            <div class="row input-container">
                <div class="col-md-12 form-inputs">
                    <label class="lab">User name</label>
                    <input type="email" class="fadeIn" ngModel name="email" required placeholder="User name" />
                    <div *ngIf="forgotForm.touched && forgotForm.controls['email'].invalid" class="errors">
                        <p>User name is invalid</p>
                    </div>
                </div>
            </div>
            <div class="login-btn-container text-center">
                <button [disabled]="inprogress" class="cg primary" [ngClass]="inprogress ? 'in-progress' : ''">Submit <span></span><span></span><span></span></button>
            </div>
        </form>
    </div>
</div>
